import type { AppTheme } from "./theme";
import acmeAvatar from "../assets/acme_avatar.png";

const acmeTheme: AppTheme = {
  primary: "#00205B",
  secondary: "#166FC8",
  info: "#166FC8",
  success: "#01801D",
  error: "#D73737",
  background: "#f6f9f8",

  scoreTier: {
    1: "#236731",
    2: "#00205B",
    3: "#C34515",
  },

  borderRadius: 12,

  avatarImage: acmeAvatar,
};

export default acmeTheme;
