import React, { useEffect, useMemo, useState } from "react";
import ReactWebChat, { createDirectLine } from "botframework-webchat";

import "./App.css";

import createStyleOptions from "./theme/createStyleOptions";
import acmeTheme from "./theme/acmeTheme";

import healthAssistantLogo from "./assets/acme_login_logo.png";

async function fetchToken() {
  // https://learn.microsoft.com/en-us/azure/bot-service/rest-api/bot-framework-rest-direct-line-3-0-authentication?view=azure-bot-service-4.0#generate-a-direct-line-token
  const secret = process.env.REACT_APP_DIRECT_LINE_SECRET;
  if (!secret)
    throw new Error(
      "Missing REACT_APP_DIRECT_LINE_SECRET variable in .env file"
    );

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${secret}`);

  const response = await fetch(
    "https://directline.botframework.com/v3/directline/tokens/generate",
    {
      method: "POST",
      headers,
    }
  );

  const data = await response.json();

  return data;
}

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    fetchToken().then(({ token }) => setToken(token));
  }, [setToken]);

  const directLine = useMemo(() => {
    if (!token) return null;
    return createDirectLine({ token });
  }, [token]);

  if (!directLine) return <p>Loading...</p>;
  return (
    <div className="App">
      <img
        src={healthAssistantLogo}
        alt="CVS Health Assistant"
        className="App-logo"
      />
      <div className="App-chatContainer">
        <ReactWebChat
          directLine={directLine}
          styleOptions={createStyleOptions(acmeTheme)}
        />
      </div>
    </div>
  );
}

export default App;
